<template>
    <el-form ref="formData" :model="formData" :rules="rules" label-position="top" class="w-full">
        <el-form-item :label="$t('calendar.client_address')" prop="selectedAddress">
            <el-select
                v-model="formData.selectedAddress"
                :placeholder="$t('calendar.client_address')"
                filterable
                class="w-full"
                @change="selectChanged"
            >
                <el-option
                    v-for="address in addressesData"
                    :key="address.uuid"
                    :value="address.uuid"
                    :label="address.client + ' - ' + address.address"
                />
            </el-select>
        </el-form-item>
    </el-form>
</template>
<script>
export default {
    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            addressesData: [],
            formData:      {
                selectedAddress: null,
            },
            rules: {
                selectedAddress: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    watch: {
        modalVisible() {
            this.formData.selectedAddress = null;
            this.$emit('change', null);
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/addresses/clients_first`);
            this.addressesData = data;
        },

        selectChanged() {
            const selectedAddress = this.addressesData.filter(address => address.uuid === this.formData.selectedAddress);

            this.$emit('change', selectedAddress[0]);
        },

        resetSelection() {
            this.formData.selectedAddress = [];
        },
    },
};
</script>
